import React from "react";
import { ParentPage } from "../components/parentPage";
import { useStaticQuery, graphql } from "gatsby";
import { StandardText } from "../components/global/standardText";

const ParentsPage = () => {
  const data = useStaticQuery(graphql`
    query ParentsPageQuery {
      sanityParentGlobal {
        _rawParentHome(resolveReferences: { maxDepth: 10 })
      }
    }
  `);
  const { _rawParentHome } = data.sanityParentGlobal;

  return (
    <ParentPage>
      {_rawParentHome &&
        _rawParentHome.map(({ text }, i) => (
          <StandardText
            key={i}
            data={{
              text,
            }}
          />
        ))}
    </ParentPage>
  );
};

export default ParentsPage;
